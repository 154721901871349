"use client";

import Button from '@mui/material/Button';
import { useSession, signIn, signOut } from "next-auth/react";
import { FunctionComponent } from "react";
import { useAuthContext } from 'src/auth/hooks';

import { paths } from 'src/routes/paths';

const AuthButton: FunctionComponent = () => {
  const { login, logout } = useAuthContext();
  const { data: session } = useSession();
  let loggedIn = false;
  session && session.user && (loggedIn = true);

  return (
    <>
    <Button 
      variant="contained"  
      rel="noopener" 
      href={`#${loggedIn ? "logged-in" : "logged-out"}`}
      onClick={() => (loggedIn ? logout() : signIn("whop", { callbackUrl: paths.dashboard.root }))}
    >
      {loggedIn ? "Sign out" : "Sign in"}
    </Button>
  </>
  );
};


export default AuthButton;

